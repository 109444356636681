const FrameViexp = ({token, id}) => {

//    window.addEventListener("message", (evt) => {
//        let msg = JSON.parse(evt.data)
//
//	if (typeof msg !== 'object' || msg === null)
//                     return;
//
//	try {
//	if(msg.sender == "viexp.justevents") {
//        if(msg.type === "ready"){
//		let response = msg;
//		let result = {
//			token: token,
//			event_id: id
//		}
//		response["response"] = result;
//		evt.source.postMessage(JSON.stringify(response), "https://viexp.net");
//		
//       }
//        return;
//	}
//	} catch(e) {}
//    }, false);

    return (
        <div className="event-location">
            <iframe
                src="https://viexp.net/justevents_v1.1/"
                className="event-location__frame3d"
                frameBorder="0"
                title="event-location"
                name="target"
            ></iframe>
        </div>
    )
}

export default FrameViexp;
