import axios from 'axios';
import React, {useEffect, useState} from 'react';




function AudioGreeting ({ id, scheme }) {




 scheme.forEach((e) => {
          const media = e.media;

          media.forEach((content) => {
              if(e.id === id){
		if(content.type === "audio_greeting"){
                 window.audio = 'https://justevents.online/storage/' + content.url
                } else {
		 window.audio = 'https://justevents.online/storage/';
		}
              }
          })

        })


//console.error('новая схема:', window.audio)
 if(!id){
   window.audio = 'https://justevents.online/storage/';
 }

let html = `<audio src=${window.audio} autoPlay></audio>`

  return (
    <div className="AudioGreeting">
        <div dangerouslySetInnerHTML={{__html: html}}/>     
    </div>
  );
}

export default AudioGreeting;

