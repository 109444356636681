import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";
import { CloseSvg } from "../../assets";
import queryString from "query-string";

const Popup = ({title, main }) => {
  const history = useHistory();

  useEffect(()=>{
	window.addEventListener("message", (evt) => {
	  let message = JSON.parse(evt.data)
	  if(message.type == "goto_stand") {
		closePopup()
	  }	
	})
  }, [])
 
  const closePopup = () => {
    const newUrl = queryString.exclude(
      history.location.pathname + history.location.search,
      ["popup"]
    );
    //console.log(newUrl, 'new url');
    history.push(newUrl);
  };

  return (
    <div className="events-popup">
      <header className="events-popup__header">
        <h2 className="events-popup__title">{title}</h2>
        <button onClick={closePopup} className="events-popup__close">
          <img src={CloseSvg} alt="" />
        </button>
      </header>
      <div className="events-popup__main">{main}</div>
    </div>
  );
};

export default Popup;
