import { useEffect, useState } from "react";
import axios from "axios";

const StandContent = ({active, setActive, id, screen_id, stand_id}) => {
const [content, setContent] = useState([]);
const src = `https://justevents.online/events/${id}/stands`;
// const token = "ewogICAgImFsZyI6ICJIUzI1NiIsCiAgICAidHlwIjogIkpXVCIKICB9.ewogICAgInN1YiI6ICI0MDkiLAogICAgInVzZXJfaWQiOiAiNDA5IiwKICAgICJpYXQiOiAxNzAyNDU5ODk3LAogICAgImV4cCI6IDE3MTAyMzU4OTcsCiAgICAibmJmIjogMTcwMjQ1OTg5NgogIH0.1gZLww9_QvNBjL2fQqTN2yKQT_aVC4q5shI6_RNg9MU"
const token = "ewogICAgImFsZyI6ICJIUzI1NiIsCiAgICAidHlwIjogIkpXVCIKICB9.ewogICAgInN1YiI6ICI0MDkiLAogICAgInVzZXJfaWQiOiAiNDA5IiwKICAgICJpYXQiOiAxNzE4ODgxMjY1LAogICAgImV4cCI6IDE3MjY2NTcyNjUsCiAgICAibmJmIjogMTcxODg4MTI2NAogIH0.GqlJxYe4cdGEP1IA-F2QW54rdKK97irgdqkWtKt6kzs"
useEffect(() => {
  axios.get(src,
  {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`, 
      Accept: "application/json"
    }
  })
  .then(response => {
    setContent(response.data.stands)
  })
  .catch((error) => {
    console.error(error)
  });

}, [])


const type = ""
//console.error("схема :", content)
content.forEach((scheme) => {
  if(scheme.id === stand_id){
    const mediaArr = scheme.media;
    mediaArr.forEach((media) => {
      if(screen_id === media.order +1){
        window.path = 'https://justevents.online/storage/' + media.url
        window.type = media.type
      }
    })
  }
})

let html = '';
switch (window.type) {
  case 'image':
    html = `<img src=${window.path} alt="stand img"/>`
    break;
  case 'video':
    html = `<video autoplay style="top: 17vh; left: 5vw; width: 90vw; height: 65vh; position: absolute; z-index: 9999; visibility: visible;" src=${window.path} controls controlslist="nodownload"></video>`
    break;
 case 'youtube':
    window.path = window.path.split('v=').pop().split('&').shift(); 
    html = `<iframe src="https://www.youtube.com/embed/${window.path}?autoplay=1&amp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
    break;
  case 'presentation':
    html = `<embed src=${window.path} />`
    break;
  default:
   html = `<img src=${window.path} alt="stand img"/>`
}

if(!active) {
 html = `<p>not found</p>`	
}

return (
        <div className={active ? "modal3d active" : "modal3d"} onClick={() => {setActive(false)}}>
            <div className="modal3d__content" onClick={ e => e.stopPropagation()}>
		<div dangerouslySetInnerHTML={{__html: html}}/>
            </div>
        </div>
    )
}

export default StandContent;

