import queryString from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import { ExpoSvg, LobbySvg, ProgramSvg, UsersSvg } from "../../assets";
import PanelLink from "../vr-event/PanelLink";
import { useSelector } from "react-redux";

const ControlPanel3D = ({ customMembersLink = null, isOnContentView, path}) => {

  
  const currentEvent = useSelector((state) => state.events.currentEvent);
  const isEventGallery = currentEvent?.room_type === "gallery";

  const history = useHistory();

  const changeLocation = (newLocation) => {
    return `?location=${newLocation}`;
  };

  const changePopup = (popupName) => {
    const parsedQuery = queryString.parse(history.location.search);
    return "?" + queryString.stringify({ ...parsedQuery, popup: popupName });
  };

  if (isOnContentView) {
    return null;
  }

  return (
    <div className="control-panel">
      {[
        {
          path: path + changeLocation("lobby"),
          text: "Ресепшн зал",
          img: LobbySvg,
          activeQueryParams: ["location=lobby"],
          disableActiveQueryParams: ["popup=members", "popup=program"],
        },
        {
          path: path  + changeLocation("place"),
          text: "Выставка",
          img: LobbySvg,
          activeQueryParams: ["location=place", "location=checkpoint"],
          disableActiveQueryParams: ["popup=members", "popup=program"],
          isHidden: isEventGallery,
        },        
        {
          path: path + "/Event3d",
          text: "Выставка 3D",
          img: LobbySvg,
          activeQueryParams: ["location=new"],
          disableActiveQueryParams: ["popup=members", "popup=program"],
          isHidden: isEventGallery,
        },

        {
          path: path + changeLocation("expo"),
          text: "План зала",
          img: ExpoSvg,
          activeQueryParams: ["location=expo"],
          disableActiveQueryParams: ["popup=members", "popup=program"],
        },
        {
          path: customMembersLink || history.location.pathname + changePopup("members"),
          text: "Участники",
          img: UsersSvg,
          activeQueryParams: ["popup=members", "location=member"],
          disableActiveQueryParams: [],
          isHidden: isEventGallery,
        },
        {
          path: history.location.pathname + changePopup("program"),
          text: "Программа",
          img: ProgramSvg,
          activeQueryParams: ["popup=program"],
          disableActiveQueryParams: [],
          isHidden: isEventGallery,
        },
      ].map(
        (l, ind) =>
          !l.isHidden && (
            <PanelLink
              key={ind}
              path={l.path}
              text={l.text}
              img={l.img}
              exact={true}
              isActive={true}
              activeQueryParams={l.activeQueryParams}
              disableActiveQueryParams={l.disableActiveQueryParams}
            />
          )
      )}
    </div>
  );
};

export default ControlPanel3D;
