import React, { useState } from "react";
import { useSelector } from "react-redux";
import DemoSite from "../../../pages/stand/DemoSite";
import DemoSiteEvent from "../../../pages/stand/DemoSiteEvent";
import { exportPageToPdf } from "../../../utils/exportPageToPdf";
import { Hint } from "../../organizer-panel";

const PromotionHeader = ({
  button,
  onClickButton,
  title,
  subtitle,
  disabled,
  withHint,
  prepareDemoData = () => {},
}) => {
  const role = useSelector((state) => state.user.profile.role);

  const [renderSite, setRenderSite] = useState(false);

  const onExportClick = async () => {
    await prepareDemoData();
    setRenderSite(true);
    exportPageToPdf({
      wrapperSelector: "#demo-site",
      hiddenElementsSelectors: [".demo-header"],
      fileName: "site-export",
    });
    setRenderSite(false);
  };

  return (
    <div className="create-promotion__header">
      {renderSite && (
        <div className="create-promotion__site-wrapper">
          {role === "организатор" && <DemoSiteEvent isDemoPreview />}
          {role === "участник" && <DemoSite isDemoPreview />}
        </div>
      )}
      <div className="create-promotion__header-main">
        <div className="create-promotion__back">
          <h5 className="create-promotion__title">{title}</h5>
        </div>
        {withHint && <Hint hintText="Для редактирования заполните обязательные предыдущие разделы" />}
        <h5 className="create-promotion__subtitle">{subtitle}</h5>
      </div>
      <div className="create-promotion__header-btns">
        <button className="blue-btn" disabled={disabled} onClick={onExportClick}>
          Экспорт
        </button>
        {button && (
          <div className="create-promotion__header-button">
            <button onClick={onClickButton} className="blue-btn" disabled={disabled}>
              {button}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromotionHeader;
