import axios from "axios";
import { authManager } from "../utils/managers";

export const Roles = {
  guest: 10,
  member: 20,
  organizer: 50,
  support: 80,
  admin: 100,
};

export const getToken = () => {
  return authManager().getToken();
};

export const setToken = (token) => {
  return authManager().setToken(token);
};

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || '/'
  // baseURL: "https://justevents.ledsoft.info/"
});

export const getOrders = ({ eventId, standId }) => {
  const token = getToken();

  let url = "/api/v1/banners/get/";

  if (standId) {
    url += `stand/${ standId }`;
  }

  if (eventId) {
    url += `event/${ eventId }`;
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const registerUser = (email, fullName, countryId, acceptedUserAgreement) => {
  const formData = new URLSearchParams();
  formData.append("email", email);
  formData.append("full_name", fullName);
  formData.append("country_id", countryId);
  formData.append("accepted_user_agreement", acceptedUserAgreement);

  return instance.post("/api/v1/register", formData, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const chooseRole = (roleId) => {
  const token = getToken();
  const formData = new URLSearchParams();
  formData.append("role", roleId);

  return instance.post("/api/v1/choose-role", formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const uploadAvatar = (avatar) => {
  const token = getToken();
  const formData = new FormData();
  formData.append("avatar", avatar);

  return instance.post("/api/v1/user/upload-avatar", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const createAccount = (
  phone,
  password,
  passwordConfirmation,
  company,
  position
) => {
  const token = getToken();
  const formData = new URLSearchParams();
  formData.append("phone", phone);
  formData.append("password", password);
  formData.append("password_confirmation", passwordConfirmation);
  formData.append("company", company);
  formData.append("position", position);

  return instance.post("/api/v1/create-account", formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const loginUser = (email, password) => {
  const formData = new URLSearchParams();
  formData.append("email", email);
  formData.append("password", password);

  return instance.post("/api/v1/login", formData, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const getEventsList = ({ dateType, name, page, limit }) => {
  const token = getToken();
  let url = "/api/v1/events?";
  const params = new URLSearchParams();
  if (dateType) {
    params.append("date_type", dateType);
  }
  if (page) {
    params.append("page", page);
  }
  if (name) {
    params.append("name", name);
  }
  if (limit) {
    params.append("limit", limit);
  }
  url += params.toString();

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const logoutUser = () => {
  const token = getToken();
  return instance.get("/api/v1/logout", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const resendVerificationMessage = () => {
  const token = getToken();
  return instance.get("/api/v1/email/resend ", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const forgotPassword = (email) => {
  const formData = new URLSearchParams();
  formData.append("email", email);

  return instance.post("/api/v1/forgot-password", formData, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const getUser = (token) => {
  if (!token) {
    token = getToken();
  } else {
    setToken(token);
  }
  if (!token) {
    return;
  }

  return instance.get("/api/v1/user/get", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const youtubePreview = (path) => {
  const token = getToken();

  return instance.get(`/api/v1/cache/upload/youtube/${ path }`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const vimeoPreview = (path) => {
  const token = getToken();

  return instance.get(`/api/v1/cache/upload/vimeo/${ path }`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const uploadImage = ({ file }) => {
  if (!file) {
    return;
  }
  const token = getToken();

  const formData = new FormData();
  formData.append("file", file);

  return instance.post(`/api/v1/cache/upload/image`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const uploadVideo = ({ file }) => {
  if (!file) {
    return;
  }
  const token = getToken();

  const formData = new FormData();
  formData.append("file", file);

  return instance.post(`/api/v1/cache/upload/video`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const uploadDocument = ({ file }) => {
  if (!file) {
    return;
  }
  const token = getToken();

  const formData = new FormData();
  formData.append("file", file);

  return instance.post(`/api/v1/cache/upload/document`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const getGlobal = (token = null) => {
  if (!token) {
    token = getToken();
  }

  return instance.get("/api/v1/global", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const getGlobalStats = (token = null, params = null) => {
  if (!token) {
    token = getToken();
  }

  const urlParams = new URLSearchParams(params);

  return instance.get("/api/v1/global/stats?" + urlParams.toString(), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const getUsers = async () => {
  const accounts = [];
  if (authManager().existTokens()) {
    const tokens = authManager().getTokens();
    for (let k in tokens) {
      let token = tokens[k];
      try {
        let { data } = await getUser(token);
        if (data && data.success && data.data.user) {
          let user = data.data.user;
          user.token = token;
          accounts.push(user);
        } else {
          authManager().removeToken(token);
        }
      } catch (e) {
        authManager().removeToken(token);
      }
    }
  }
  return accounts;
};

export const updateUser = ({
  email,
  full_name,
  country_id,
  position,
  company,
  phone,
  birth,
}) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("email", email);
  formData.append("full_name", full_name);
  formData.append("country_id", country_id);
  formData.append("position", position);
  formData.append("company", company);
  formData.append("phone", phone);
  formData.append("birth", birth);

  return instance.post("/api/v1/user/update", formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const resetPassword = ({ email, token, password, password_confirmation }) => {
  const formData = new URLSearchParams();
  formData.append("token", token);
  formData.append("email", email);
  formData.append("password", password);
  formData.append("password_confirmation", password_confirmation);

  return instance.post("/api/v1/reset-password", formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    },
  });
};

export const changeCurrentPassword = ({
  old_password,
  password,
  password_confirmation,
}) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("password", password);
  formData.append("old_password", old_password);
  formData.append("password_confirmation", password_confirmation);

  return instance.post("/api/v1/user/update-password", formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const registerOnEvent = ({ event_id, info }) => {
  const token = getToken();

  return instance.post(
    "/api/v1/event/register",
    { event_id, info },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ token }`,
        Accept: "application/json",
      },
    }
  );
};

export const getMyEvents = ({ date_type }) => {
  const token = getToken();

  return instance.get(`/api/v1/my-events?date_type=${ date_type }`, {
    headers: {
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const registerMember = (fields) => {
  const token = getToken();

  const formData = new URLSearchParams();
  for (let k in fields) {
    formData.append(k, fields[k]);
  }

  return instance.post("/api/v1/create-participant", formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const getCurrencies = () => {
  const token = getToken();

  return instance.get("/api/v1/currencies", {
    headers: {
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const removeProfileAvatar = () => {
  const token = getToken();

  return instance.get("/api/v1/user/remove-avatar", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};

export const getEventInfo = ({ event_id }) => {
  const token = getToken();

  return instance.get(`/api/v1/event?id=${ event_id }`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${ token }`,
      Accept: "application/json",
    },
  });
};
