import styles from './AudioPlayerEvent.module.css'
import React, { useState } from 'react';



const AudioPlayerEvent = () => {

  const [audio, setAudio] = useState('https://justevents.online/storage/events/Muzei_vystavochnyi_zal.mp3');
	
  function playAudio(){
	setAudio('https://justevents.online/storage/events/Muzei_vystavochnyi_zal.mp3');
  }

  function stopAudio(){
	setAudio('https://justevents.online/storage/events/');
  }

  return (    
    <>
        <audio src={audio} autoPlay ></audio>
        <div className={styles.audio_player}>
          <button onClick={playAudio} className={styles.button}>▶</button>
          <button onClick={stopAudio} className={styles.button}>◾</button>
        </div>
    </>
  );
}

export default AudioPlayerEvent;
