import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { setCurrentEvent} from "../../redux/eventReducer";
import queryString from "query-string";

import { Header, Popup, Program, SidePanel, Sidebar, ShareContacts } from "../../components/vr-event";

import Members from '../../components/event3d/Members/indexMember';

import ControlPanel3D from "../../components/event3d/ControlPanel3D";

import { getEventInfo } from "../../api";

import axios from "axios";

import AudioGreeting from "../../components/event3d/AudioGreeting";
import StandContent from "../../components/event3d/StandContent";
import FrameViexp from "../../components/event3d/FrameViexp";
import AudioPlayerEvent from "../../components/event3d/AudioPlayerEvent";

const Event3d = () => {
  const [modalActive, setModalActive] = useState(false);
  const [contentBann, setContentBann] = useState([]);
  const [isOnContentView, setIsOnContentView] = useState(false);
  const [standId, setStandId] = useState(null);
  const location = useLocation();
  const pathTo3D = location.pathname.replace('/Event3d', '');
  const dispatch = useDispatch();

  const parsedQuery = queryString.parse(location.search);
  const iframeRef = useRef(null);
  const { id } = useParams();
  const src = `https://justevents.online/events/${id}/stands`;
  const [eventTitle, setEventTitle] = useState("");
  const stand = useSelector((state) => state.events.currentStandInPlayer);
  const profile = useSelector((state) => state.user.profile);
  
  const isSelfUserStand = stand?.user_id === profile?.id;
	
	// копия списка участников
	const members = useSelector(state => state.events.members);
	const [membersFiltered, setMembersFiltered] = useState(members);

	//обновление списка
	useEffect(() => {
		setMembersFiltered(members);
	}, [members]);

//	console.log(membersFiltered.map((m) => 15 !== m.status_code ? null : m.id));

const token = "ewogICAgImFsZyI6ICJIUzI1NiIsCiAgICAidHlwIjogIkpXVCIKICB9.ewogICAgInN1YiI6ICI0MDkiLAogICAgInVzZXJfaWQiOiAiNDA5IiwKICAgICJpYXQiOiAxNzE4ODgxMjY1LAogICAgImV4cCI6IDE3MjY2NTcyNjUsCiAgICAibmJmIjogMTcxODg4MTI2NAogIH0.GqlJxYe4cdGEP1IA-F2QW54rdKK97irgdqkWtKt6kzs"

  useEffect(() => {
      const fetchEventInfo = async () => {
        try {
          const { data } = await getEventInfo({ event_id: id });

          dispatch(setCurrentEvent(data.data.item));

          setEventTitle(data.data.item.name);
        } catch (error) {
          console.log(error);
        }
      };
      fetchEventInfo();
  }, [id, setEventTitle, dispatch]);

  function openModal(json){
    console.log('Open modal')
    let messageFrom3D = json.data;
    let obj = JSON.parse(messageFrom3D)
    let check = JSON.stringify({
      "sender":"viexp.justevents",
      "version":1,
      "type":"select",
      "showroom_id":0,
      "stand_id":obj.stand_id,
      "screen_id":obj.screen_id
    });

    window.stand_id = obj.stand_id;
    window.screen_id = obj.screen_id;
    if(obj.type == "stand_enter"){
	setStandId(obj.stand_id);    
    }
    if(obj.type == "stand_leave"){
	setStandId(null);
    }
    if ((messageFrom3D === check) && (obj.stand_id !== null) && (obj.screen_id !== 0)){
      setModalActive(true)
    }

  }

//console.log('тут', standId)

useEffect(() => {
    
async function goToBannerLink(json){
    let messageFrom3D = json.data;
    let obj = JSON.parse(messageFrom3D)
    let check = JSON.stringify({
      "sender":"viexp.justevents",
      "version":1,
      "type":"select",
      "showroom_id":0,
      "stand_id":obj.stand_id,
      "screen_id":obj.screen_id
  });

    window.stand_id = obj.stand_id;
    window.screen_id = obj.screen_id;
    let url = await getURL();
    if ((messageFrom3D === check) && (obj.stand_id === null)){
      window.open(window.url);
    }
  }
 
  
	 window.addEventListener("message", (evt) => {
		openModal(evt);
		goToBannerLink(evt);

             try {
                 let msg = JSON.parse(evt.data);

                 // Usage example
                 if (typeof msg !== 'object' || msg === null)
                     return;

                 // Message is 'request' from viexp
                 if (msg.sender == "viexp.justevents") {

                     // NOTE:

                     if (msg.type == "ready") {

						 // VIEXP is ready for init and needs special answer.
							 // We will modify msg to be response this way:
						 let response = msg;
						 let result = {
							 token		: token,
							 event_id	: id,
						 }
						 response["response"] = result;
						 evt.source.postMessage(JSON.stringify(response), evt.origin);

                 // Message is 'response'
                     if (msg.type == "stand_enter") {
                         console.log('id стенда:', msg.stand_id)


                         // NOTE: Your action here!

                         return;
                     }

					 }

                     return;
                 }                 
             } catch (e) {
             }
         }, false);

async function getURL(){  
 await  axios.get(src,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
        Accept: "application/json"
      }
    })
    .then(response => {
      setContentBann(response.data.stands)
    })
    .catch((error) => {
      console.error(error)
    });
  }

  }, [])

contentBann.forEach((scheme) => {
const banners = scheme.banners;
banners.forEach((banner) => {
  console.log(banner)
  if(banner.type === "exhibition" && banner.order === window.screen_id){
    window.url = banner.url
  }
})
});

 const shareContactsIsVisible = !isSelfUserStand && !isOnContentView && standId;

return (
  <div className="events-page">
    <div className="events-page__header">
      <Header title={eventTitle} />
    </div>


    <main className="events-page__main">
      < FrameViexp token={token} id={id}/>

      {(parsedQuery.popup === "program" && <Popup title="Программа мероприятия" main={<Program />} />) ||
        (parsedQuery.popup === "members" && (
        <Popup title="Участники" main={<Members frameRef={iframeRef} scheme={contentBann}/>} />
      )) ||
      null}

      <div className="events-page__control-panel">
        <ControlPanel3D path={pathTo3D} />
      </div>



	
    
          {shareContactsIsVisible && (
            <ShareContacts selectedStandId={standId} isOnContentView={isOnContentView} />
          )} 


	{standId && (
	   <AudioGreeting id={standId} scheme={contentBann} />
	)}	

          <div className="events-page__side-panel">
            {parsedQuery.sidebar ? (
              <Sidebar />
            ) : (
              <SidePanel frameRef={iframeRef} />
            )}
          </div>
 <AudioPlayerEvent />
      <StandContent
        active={modalActive}
        setActive={setModalActive}
        id={id}
        stand_id={window.stand_id}
        screen_id={window.screen_id}
      />
    </main>

  </div>
  )
}

export default Event3d;
