import { immediateToast } from "izitoast-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { changeTariff } from "../../api/organizer";
import PlanExample from "../../components/events/PlanExample";
import { pageNames } from "../../components/organizer-panel/CreateEvent/EventSidebar";
import { ConfirmPopup, Layout } from "../../components/shared";
import { NavStand, StandHeader } from "../../components/stand";
import { TariffsNav } from "../../components/stand/tariffs";
import { useCreateEventPageDisabled } from "../../hooks/create-event/usePageDisabled";
import { useConfirmPopup } from "../../hooks/useConfirmPopup";
import { fetchEvent, fetchTariff, setFillingPageName } from "../../redux/eventReducer";
import { errorMessage } from "../../utils/errorMessage";

const TariffsStands = () => {
  const dispatch = useDispatch();
  const { popupInfo, setPopupInfo, closePopup } = useConfirmPopup();
  const [isPublished, setIsPublished] = useState(false);
  const [stands, setStands] = useState(false);

  const currentEvent = useSelector((state) => state.events.currentEvent);
  const currentPageFillingIndex = currentEvent?.fillingPageIndex;

  const { id: eventId } = useParams();
  const tariffStands = useSelector((state) => state.events.tariffStands);

  useEffect(() => {
    dispatch(fetchTariff(eventId));
  }, [dispatch, eventId]);

  useEffect(() => {
    if (tariffStands) {
      setStands(tariffStands);
    }
  }, [tariffStands]);

  useEffect(() => {
    const isPublished = currentPageFillingIndex === 1.2 || currentPageFillingIndex >= 2;
    setIsPublished(isPublished);
  }, [currentPageFillingIndex]);

  const editRatePrice = (standIndex, newPrice) => {
    setStands((prev) =>
      prev.map((s, i) => (i === standIndex ? { ...s, price: newPrice } : s))
    );
  };

  const onPublishBtnClick = () => {
    setPopupInfo({
      title: "Публикация",
      text: "Вы действительно хотите опубликовать тарифы участия?",
      onCancelClick: closePopup,
      onConfirmClick: onPublishConfirmClick,
      isOpened: true,
    });
  };

  const onPublishConfirmClick = async () => {
    try {
      if (stands?.some((t) => String(t.price).trim() === "")) {
        errorMessage("Заполните все тарифы участия");
        closePopup();
        return;
      }

      await changeTariff({
        eventId,
        setting: "stands",
        items: stands,
      });

      dispatch(
        setFillingPageName({
          eventId,
          name: pageNames.TARIFF_ADS,
        })
      );

      dispatch(fetchEvent({ eventId }));

      immediateToast("success", {
        message: "Тарифы участия успешно опубликованы",
        position: "topCenter",
      });

      setIsPublished(true);
      closePopup();
    } catch (error) {
      immediateToast("error", {
        message: "Произошла ошибка",
        position: "topCenter",
      });

      console.log(error);
    }
  };

  const onEditBtnClick = () => {
    setPopupInfo({
      title: "Редактирование",
      text: "Вы действительно хотите редактировать уже опубликованные тарифы участия?",
      onCancelClick: closePopup,
      onConfirmClick: onEditConfirmClick,
      isOpened: true,
    });
  };

  const onEditConfirmClick = async () => {
    try {
      setIsPublished(false);
      closePopup();
    } catch (error) {
      immediateToast("error", {
        message: "Произошла ошибка",
        position: "topCenter",
      });

      console.log(error);
    }
  };

  const isPageDisabled = useCreateEventPageDisabled(pageNames.TARIFF_STANDS);

  return (
    <Layout>
      <div className="organizer-panel__main">
        <NavStand />
        <div className="stand-settings">
          {popupInfo.isOpened && (
            <ConfirmPopup popupInfo={popupInfo} onCloseClick={closePopup} />
          )}
          <div className="stand-settings">
            <StandHeader title="Тарифы" withHint={isPageDisabled} />
            <div className="participants">
              <TariffsNav />
              <div className="participants-main">
                <div className="participants__tarifs">
                  <div className="participants__stand-tarifs stand-tarifs">
                    <table className="table table-bordered participants-table stand-tarifs__table">
                      <thead className="table__head">
                        <tr>
                          <th>Тип стенда</th>
                          <th>Кол-во</th>
                          <th>Стоимость за шт., руб</th>
                        </tr>
                      </thead>
                      <tbody className="table__body">
                        {stands &&
                          stands.length > 0 &&
                          stands.map((s, i) => (
                            <tr key={s.id}>
                              <td>Тип стенда: {s.name}</td>
                              <td>{s.count} шт.</td>
                              <td>
                                {isPublished ? (
                                  <div style={{ margin: "10px 5px" }}>{s.price}</div>
                                ) : (
                                  <input
                                    type="text"	
                                    value={s.price}
                                    onChange={(e) =>
                                      editRatePrice(
                                        i,
                                        e.target.value.replace(/[A-Za-zА-Яа-яЁё]/g, '')
                                      )
                                    }
                                    placeholder="Введите стоимость"
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="stand-tarifs__buttons">
                      {isPublished ? (
                        <button
                          onClick={onEditBtnClick}
                          className="red-btn"
                          disabled={isPageDisabled}
                        >
                          Редактировать
                        </button>
                      ) : (
                        <button
                          onClick={onPublishBtnClick}
                          className="red-btn"
                          disabled={isPageDisabled}
                        >
                          Опубликовать
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="participants-history">
                  <PlanExample />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TariffsStands;
