import cn from "classnames";
import moment from "moment";
import React, { useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector } from "react-redux";
import { useEffect } from "react";

// const fake = [
//   {
//     name: "02 фев",
//     standViews: 4000,
//     visitors: 2400,
//     amt: 2400,
//   },
// ];

const Attendance = () => {
  const attendance = useSelector(state => state.events.analytics?.attendance);
  const [dateInterval, setDateInterval] = useState("week"); // week || month
  const [data, setData] = useState([]);

  useEffect(() => {
    const startDate = dateInterval === "week" ? moment().subtract(7, 'days') : moment().subtract(1, 'months');
    const items = [];
    if(attendance && attendance.length){
      attendance.forEach((it, k) => {
        const date = moment(it.date, "YYYY-MM-DD");
        if(date.isAfter(startDate)){
          items.push(it);
        }
      });
    }
    // sort with date before set
    setData(items.sort((a, b) => (a.date > b.date) ? 1 : ((a.date < b.date) ? -1 : 0)));
  }, [attendance, dateInterval]);

  return (
    <div className="stand-analytics__attendance analytics-attendance">
      <div className="analytics-attendance__header">
        <h3 className="analytics-attendance__title">Статистика посещаемости</h3>
        <div className="analytics-attendance__date-btns">
          <button
            className={cn("analytics-attendance__date-btn", {
              "analytics-attendance__date-btn--active": dateInterval === "week",
            })}
            onClick={() => setDateInterval("week")}
          >
            Неделя
          </button>
          <button
            className={cn("analytics-attendance__date-btn", {
              "analytics-attendance__date-btn--active": dateInterval === "month",
            })}
            onClick={() => setDateInterval("month")}
          >
            Месяц
          </button>
        </div>
      </div>
      <div className="analytics-attendance__main">
        <ResponsiveContainer width="100%" height={350}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="0" vertical={false} />
            <XAxis padding={{ left: 0, right: 0 }} fontSize={dateInterval === "month" ? 10 : 12} dataKey="name" />
            <YAxis ticksCount={6} axisLine={false} />
            <Tooltip />
            <Legend
              margin={{ top: 10 }}
              wrapperStyle={{
                marginTop: "10px",
              }}
            />
            <Line type="monotone" name="Посетители" dataKey="visitors" stroke="#C90113" />
            <Line
                type="monotone"
                name="Клики"
                dataKey="hits"
                stroke="#727E8E"
              />
            {/* {role === "организатор" && (
              
            )} */}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Attendance;
