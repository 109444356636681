import {
  AgreementPdf,
  landingFooterIcon1,
  landingFooterIcon2,
  landingFooterIcon3,
  PolicyPdf,
} from "../../assets";

const Footer = () => {
  return (
    <div className="landing-footer">
      <div className="landing-footer__bg-icons">
        <img
          className="landing-footer__bg-icon landing-footer__bg-icon--first"
          src={landingFooterIcon1}
          alt=""
        />
        <img
          className="landing-footer__bg-icon landing-footer__bg-icon--second"
          src={landingFooterIcon2}
          alt=""
        />
        <img
          className="landing-footer__bg-icon landing-footer__bg-icon--third"
          src={landingFooterIcon3}
          alt=""
        />
      </div>
      <div className="landing-footer__left-side">
        <p>Компания ООО «ДЖАСТИВЕНТС», 2021</p>
        <p>ИНН 9709070090</p>
        <p>ОГРН: 12177001215949</p>
	<p>109028, Г.МОСКВА, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ БАСМАННЫЙ, ПЕР ХОХЛОВСКИЙ, Д. 15, ПОМЕЩ. 3П</p>
      </div>
      <div className="landing-footer__right-side">
        <p>
          <a href={PolicyPdf} rel="noreferrer" target="_blank">
            Политика конфиденциальности
          </a>
        </p>
        <a href={AgreementPdf} rel="noreferrer" target="_blank">
          Пользовательское соглашение
        </a>
        <p>Служба поддержки:</p>
        <p>+7 968 531-99-83</p>
        <p>support@justevents.online</p>
      </div>
      {/* {false && ( // disable icons
        <div className="landing-footer__icons">
          <button>
            <img src={landingFacebookIcon} alt="" />
          </button>
          <button>
            <img src={landingInstagramIcon} alt="" />
          </button>
          <button>
            <img src={landingTwitterIcon} alt="" />
          </button>
        </div>
      )} */}
    </div>
  );
};

export default Footer;
