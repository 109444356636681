import React, { useEffect, useState } from "react";
import axios from 'axios';

import { useParams} from "react-router-dom";

export const MemberCard = ({
  image,
  groupName,
  isLobby,
  standNumber,
  fullName,
  email,
  numbers,
  role,
  onClick,
  id
}) => {

  const token = 'ewogICAgImFsZyI6ICJIUzI1NiIsCiAgICAidHlwIjogIkpXVCIKICB9.ewogICAgInN1YiI6ICI0MDkiLAogICAgInVzZXJfaWQiOiAiNDA5IiwKICAgICJpYXQiOiAxNzAyNDU5ODk3LAogICAgImV4cCI6IDE3MTAyMzU4OTcsCiAgICAibmJmIjogMTcwMjQ1OTg5NgogIH0.1gZLww9_QvNBjL2fQqTN2yKQT_aVC4q5shI6_RNg9MU';
  const src = `https://justevents.online/events/498/stands`;

 const [data, setData] = useState(null);

//      useEffect(() => {
//        axios.get(src,
//          {
//            headers: {
//              "Content-Type": "application/x-www-form-urlencoded",
//              Authorization: `Bearer ${token}`, 
//              Accept: "application/json"
//            }
//          })
//          .then(response => {
//            let dat = response.data.stands
//
//	async function getPerson (){
//	  await dat.forEach((stand) => {
//              if(stand.id === id){
//                window.contact = stand.contact_person
//		console.log(window.contact)
//             }
//            });
//	}
//	getPerson();
//          })
//          .catch((error) => {
//            console.error(error)
//          });
//      },[])

//console.error(id);

  return (
    <li onClick={onClick} className="members-popup__item member-item">
      <div>
        <div className="member-item__header">
          <div className="member-item__image">
            <img src={image} alt="#" className="" />
          </div>
          <span className="member-item__name">{groupName}</span>
          <div className="member-item__stend">{!isLobby ? `Стенд #${standNumber}` : "Лобби"}</div>
        </div>
        <div className="member-item__main">
          <div className="member-item__sector">
            <h3>ФИО</h3>
            <span className="member-item__info">{fullName}</span>
          </div>
          <div className="member-item__sector">
            <h3>E-mail</h3>
            <span className="member-item__info">{email}</span>
          </div>
          <div className="member-item__sector">
            <h3>Телефон</h3>
            <span className="member-item__info">{numbers}</span>
          </div>
          <div className="member-item__sector">
            <h3>Должность</h3>
            <span className="member-item__info">{role ? role : 'Не указано'}</span>
          </div>
        </div>
      </div>
    </li>
  );
};
